import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import { getItemFromStorage } from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';
import { setKpiPeriods } from '../../admin/redux/slice';

const appStoreKeyIV = [env_constants.APP_STORE_KEY, env_constants.APP_STORE_IV];

export const fetchValues = createAsyncThunk(
  'detail/values',
  async (apiParams: { token: string }, thunkAPI) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.AR_API_BASE_URL}/Values`,
      options,
    );

    const data = decryptData(response.data);

    const {
      clientTypes,
      paymentTerms,
      invoiceStatus,
      overdueInvoicesTypes,
      kpiPeriods,
      warningMessage,
      anyWarning,
    } = data;

    thunkAPI.dispatch(setKpiPeriods(kpiPeriods ?? []));

    return {
      clientTypes,
      paymentTerms,
      invoiceStatus,
      overdueInvoicesTypes,
      warningMessage,
      anyWarning,
    };
  },
);

export const fetchUsers = createAsyncThunk(
  'detail/fetch-appstore-users',
  async (apiParams: { token: string; sessionId: string }) => {
    // @ts-ignore
    const clientData = JSON.parse(getItemFromStorage('client_ip'));

    const ip = clientData.ipv4;

    let headers: any = {
      'Content-type': 'application/json',
      Authorization: `Bearer ${apiParams.token}`,
    };

    const clientId = encryptData(
      `${window.navigator.userAgent}/${ip}/${apiParams.sessionId}`,
      appStoreKeyIV,
    );

    headers = {
      ...headers,
      clientid: clientId,
    };

    const response = await axios.get(
      `${env_constants.APP_STORE_API}/User/users/${env_constants.APP_ID}/9`,
      {
        headers,
      },
    );

    const data = decryptData(response.data, appStoreKeyIV);

    return data.emails;
  },
);

export const fetchNotes = createAsyncThunk(
  'detail/fetch-notes',
  async (apiParams: { token: string; clientId: number }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.AR_API_BASE_URL}/Details/notes/${apiParams.clientId}`,
      options,
    );

    const data = decryptData(response.data);

    return data.notes;
  },
);

type AddNotesReqBody = {
  clientIds: number[];
  sendSlackMessage: boolean;
  message: string;
  userId: string;
  sessionId: string;
};

export const addNotes = createAsyncThunk(
  'detail/add-notes',
  async (apiParams: { token: string; reqBody: AddNotesReqBody }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.reqBody);

    const response = await axios.post(
      `${env_constants.AR_API_BASE_URL}/Details/notes`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

export type FetchDetailReqBody = {
  userId: string;
  sessionId: string;
  kpiPeriodId?: number | null;
  clientType?: string;
  paymentTerms?: string;
  advisor?: string;
  status?: string;
  searchValue?: string;
  closedWonStart?: string | null;
  closedWonEnd?: string | null;
};
export const fetchDetails = createAsyncThunk(
  'detail/fetch',
  async (apiParams: { token: string; reqBody: FetchDetailReqBody }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.reqBody);

    const response = await axios.post(
      `${env_constants.AR_API_BASE_URL}/Details`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);

import { LockFilled } from '@ant-design/icons';
import { notification, Result } from 'antd';
import { Buffer } from 'buffer';
import query_string from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import NotFound from '../../foundation/components/not_found/NotFound';
import { getItemFromStorage } from '../../foundation/utils/storageHandler';
import env_constants from '../../internals/env/env_constants.json';
import { useAppDispatch } from '../../store/hooks';
import {
  fetchClientData,
  refreshToken,
} from '../authentication/redux/async_thunks';
import { selectClientIp, selectUser } from '../authentication/redux/selectors';
import { setClientIp } from '../authentication/redux/slice';
import Summarization from '../dashboard/Dashboard';
import Details from '../details/Details';
import { fetchUsers, fetchValues } from '../details/redux/async_thunks';

const Startup = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [isError, setIsError] = useState(false);

  const clientIp = useSelector(selectClientIp);

  const location = useLocation();

  const user = useSelector(selectUser);

  const queryString: any = useMemo(
    () => query_string.parse(location.search),
    [location.search],
  );

  useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  const getAPIParams = () => {
    type refreshTokenParamsType = {
      userId: string;
      sessionId: string;
      token?: string;
      appId: number;
      clientId?: number;
      roleId: number;
    };

    let apiParams: refreshTokenParamsType | undefined = undefined;

    const currentUserData = getItemFromStorage('user');

    // If the appAccess key is in the query string then parse using base64.
    if (queryString && queryString?.appAccess) {
      const buff = Buffer.from(queryString?.appAccess, 'base64');
      const text = buff.toString();

      const parsedData = JSON.parse(text);

      apiParams = {
        userId: parsedData.UserId,
        sessionId: parsedData.SessionId,
        appId: env_constants.APP_ID,
        roleId: parsedData.RoleId,
      };
    } else if (currentUserData) {
      const data = JSON.parse(currentUserData);

      apiParams = {
        userId: data.user_id,
        sessionId: data.session_id,
        appId: env_constants.APP_ID,
        roleId: data.role_id,
        clientId: data.client_id,
        token: data.token,
      };
    }

    return apiParams;
  };

  const setAuthData = async () => {
    try {
      /**
       * Fetch the values from browser's storage.
       */
      const apiParams = getAPIParams();

      /**
       * Call the refreshToken API to get the updated values.
       */
      let updatedUserData: any = undefined;

      if (apiParams) {
        updatedUserData = await dispatch(
          refreshToken({
            data: apiParams,
          }),
          // @ts-ignore
        ).unwrap();
      } else if (user) {
        updatedUserData = { ...user };
      } else {
        setIsError(true);
      }

      const response = await dispatch(
        fetchValues({ token: updatedUserData?.token }),
      ).unwrap();

      if (response.anyWarning) {
        let warningMessage = response.warningMessage;

        const users = await dispatch(
          fetchUsers({
            token: updatedUserData?.appStoreToken?.token,
            sessionId: updatedUserData.session_id,
          }),
        ).unwrap();

        warningMessage += '\n' + users.join('\n');

        notification.warning({
          message: 'Warning!',
          description: warningMessage,
          closable: true,
          duration: 5,
        });
      }

      if (user) {
        return setIsLoading(false);
      } else if (updatedUserData?.user_id) {
        if (location.pathname === '/detail') {
          navigate('/detail');
        } else {
          /**
           * Handle browser routing based on the avaialble values.
           */
          navigate('/dashboard');
        }
      }
    } catch (error) {
      console.log(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClientIp = async () => {
    try {
      await dispatch(fetchClientData()).unwrap();
      setAuthData();
    } catch (error) {
      dispatch(setClientIp(undefined));
    }
  };

  useEffect(() => {
    if (!clientIp) {
      fetchClientIp();
    }
  }, [clientIp]);

  if (isError) {
    return (
      <Result
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        icon={<LockFilled />}
      />
    );
  }

  return (
    <>
      {isLoading && <FullPageLoader style={{ opacity: 1 }} />}
      <Routes>
        <Route path="/detail" Component={Details} />
        <Route path="/dashboard" Component={Summarization} />
        <Route path="*" Component={NotFound} />
      </Routes>
    </>
  );
};

export default Startup;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchClientData, refreshToken } from './async_thunks';
import { AuthState, ClientIp, User } from './types';

const initialState: AuthState = {
  oauth_info: undefined,
  user: undefined,
  location_info: undefined,
  clientIp: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, action: PayloadAction<User | undefined>) => {
      state.user = action.payload;
    },
    setClientIp: (state, action: PayloadAction<ClientIp>) => {
      state.clientIp = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchClientData.fulfilled, (state, action) => {
      state.clientIp = action.payload;
    });
  },
});

export const { setUser, setClientIp } = authSlice.actions;

export default authSlice.reducer;

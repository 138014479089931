import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../store/hooks';
import { selectKpiPeriodDefaultId } from '../admin/redux/selectors';
import { selectUser } from '../authentication/redux/selectors';
import BarChartGraphCard from './charts/BarChartGraphCard';
import Charts from './charts/Charts';
import Filters from './filters/Filters';
import { fetchDashboard, FetchDashboardReqBody } from './redux/async_thunks';
import StatsRow from './stats_card/StatsRow';

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();

  // State to manage loading status of the dashboard data
  const [isDashboardLoading, setIsDashboardLoading] = useState(true);

  // Select user data from Redux store
  const user = useSelector(selectUser);

  const kpiPeriodDefaultId = useSelector(selectKpiPeriodDefaultId);

  // Function to handle filter changes and fetch new data
  const onFilter = (values?: any) => {
    setIsDashboardLoading(true);
    fetchData(values);
  };

  type FilterOpts = {
    kpiPeriodId?: number;
  };

  // Function to fetch dashboard data
  const fetchData = async (values?: FilterOpts) => {
    if (user && kpiPeriodDefaultId) {
      try {
        setIsDashboardLoading(true);

        // Prepare request body with necessary user details and optional filter values
        const data: FetchDashboardReqBody = {
          userId: user.user_id,
          sessionId: user.session_id,
          kpiPeriodId: kpiPeriodDefaultId,
          closedWonStart: null,
          closedWonEnd: null,
        };

        await dispatch(
          fetchDashboard({
            token: user.token,
            reqBody: values ? { ...data, ...values } : data,
          }),
        ).unwrap();

        setIsDashboardLoading(false);
      } catch (error) {
        setIsDashboardLoading(false);
      }
    }
  };

  // Effect to fetch data when the component mounts or user changes
  useEffect(() => {
    fetchData();
  }, [user, kpiPeriodDefaultId]);

  return (
    <div className="ar-summary">
      {/* Show loader while dashboard data is being fetched */}
      {isDashboardLoading && <FullPageLoader />}
      {/* Filters component to allow data filtering */}
      <Filters isLoading={isDashboardLoading} onFilter={onFilter} />

      <Row gutter={[20, 16]} style={{ marginBottom: 50 }} align={'stretch'}>
        <Col span={14}>
          <StatsRow />
        </Col>
        <Col span={10}>
          <BarChartGraphCard />
        </Col>
      </Row>

      {/* <StatsRow /> */}
      {/* Component to display various charts */}
      <Charts onFilter={onFilter} />
    </div>
  );
};

export default Dashboard;

import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import {
  addItemToStorage,
  getItemFromStorage,
} from '../../../foundation/utils/storageHandler';
import env_constants from '../../../internals/env/env_constants.json';

const appStoreKeyIV = [env_constants.APP_STORE_KEY, env_constants.APP_STORE_IV];

type refreshTokenParamsType = {
  userId: string;
  token?: string;
  sessionId: string;
  appId: number;
  clientId?: number;
  roleId: number;
};

export const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (apiParams: { data: refreshTokenParamsType }, thunkAPI) => {
    // @ts-ignore
    const clientData = JSON.parse(getItemFromStorage('client_ip'));
    let encryptedData = null;

    encryptedData = encryptData(apiParams.data, appStoreKeyIV);

    const ip = clientData.ipv4;

    let headers: any = {
      'Content-type': 'application/json',
    };

    const clientId = encryptData(
      `${window.navigator.userAgent}/${ip}/${apiParams.data.sessionId}`,
      appStoreKeyIV,
    );

    headers = {
      ...headers,
      clientid: clientId,
    };

    const response = await axios.patch(
      `${env_constants.APP_STORE_API}/Auth/refresh-token`,
      encryptedData,
      {
        headers,
      },
    );

    let data: any = {};

    data = decryptData(response.data, appStoreKeyIV);

    let userData: any = {};

    userData = {
      session_id: data.sessionId,
      user_id: data.userId,
      expiry: data.appToken.expiry,
      token: data.appToken.token,
      role_id: apiParams.data.roleId,
      email: data.userEmail,
      first_name: data.userFirstName,
      last_name: data.userLastName,
      picture: data.userPicture,
      appStoreToken: data.appStoreToken,
    };

    addItemToStorage('user', JSON.stringify(userData));

    return userData;
  },
);

export const fetchClientData = createAsyncThunk('auth/clientData', async () => {
  const response = await axios.get(`${env_constants.APP_STORE_API}/Client/ip`, {
    headers: {
      'Content-type': 'application/json',
      clientid: encryptData(`${window.navigator.userAgent}`),
    },
  });
  const decryptedDataResponse = decryptData(response.data, appStoreKeyIV);
  const clientData = { ipv4: decryptedDataResponse.ip };
  sessionStorage.setItem('client_ip', JSON.stringify(clientData));
  return clientData.ipv4;
});

export const logout = createAsyncThunk(
  'auth/logout',
  async (apiParams: { token: string; data: any }) => {
    try {
      let encryptedData: any = undefined;
      let clientId = '';
      const API_URL = `${env_constants.APP_STORE_API}/Auth/logout`;

      // @ts-ignore
      const clientData = JSON.parse(getItemFromStorage('client_ip'));
      // @ts-ignore
      const getUser = JSON.parse(getItemFromStorage('user'));
      const ip = clientData.ipv4;
      encryptedData = encryptData(apiParams.data, appStoreKeyIV);
      clientId = encryptData(
        `${window.navigator.userAgent}/${ip}/${getUser.session_id}`,
        appStoreKeyIV,
      );

      const options = {
        headers: {
          Authorization: `Bearer ${apiParams.token}`,
          'Content-type': 'application/json',
          clientid: clientId,
        },
      };

      const res = await axios.delete(API_URL, {
        ...options,
        data: encryptedData,
      });

      return decryptData(res.data, appStoreKeyIV);
    } catch (error) {
      console.log(error);
    }
  },
);
